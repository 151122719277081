body{
  background-color: #579d5380 !important;
}
.logo {
  height: 32px;
  margin: 16px;
  width: 75%;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

/* login */

.containerPrincipal {
  background-color:"red" ;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 40px 10px 40px ;
  border-radius: 5%;
}

.containerSecundario{
  text-align: center;
  
}








